import { render, staticRenderFns } from "./VineyardProfileComponent.vue?vue&type=template&id=79c9e5ca&scoped=true"
import script from "./VineyardProfileComponent.vue?vue&type=script&lang=js"
export * from "./VineyardProfileComponent.vue?vue&type=script&lang=js"
import style0 from "./VineyardProfileComponent.vue?vue&type=style&index=0&id=79c9e5ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c9e5ca",
  null
  
)

export default component.exports